// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-details-page-talk-details-page-js": () => import("./../../../src/components/DetailsPage/TalkDetailsPage.js" /* webpackChunkName: "component---src-components-details-page-talk-details-page-js" */),
  "component---src-components-details-page-workshop-details-page-js": () => import("./../../../src/components/DetailsPage/WorkshopDetailsPage.js" /* webpackChunkName: "component---src-components-details-page-workshop-details-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-landing-3467-js": () => import("./../../../src/pages/landing-3467.js" /* webpackChunkName: "component---src-pages-landing-3467-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-login-nr-js": () => import("./../../../src/pages/login/nr.js" /* webpackChunkName: "component---src-pages-login-nr-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

